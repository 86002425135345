import React from 'react';
import ReactDOM from 'react-dom';
import { mergeStyles } from 'office-ui-fabric-react';
import { theme } from './styles';
import Background from './laptop.png';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import App from './App';
import reportWebVitals from './reportWebVitals';


// Inject some global styles
mergeStyles({
  body: {
  },
  selectors: {
    ':global(body), :global(html), :global(#app)': {
      margin: 0,
      padding: 0,
      backgroundColor: theme.palette.themeLighterAlt,
      height: "100%"
    },
  ':global(#bgContainer)': {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "100%",
    //maxWidth: "1600px",
    margin: "0 auto",
    padding: "0px 20px",
    display: "flex",
    minHeight: "100%",
    height: "100%",  
    alignItems: "flex-start",
    overflow: "auto",     
    selectors: {
      "@media (max-width: 960px)": {
          padding: "0px 0px",
          height: "0px"
      }
    }
  },
  ':global(#root)': {
    margin: "0 auto",
    backgroundColor: theme.palette.white,
    padding: "0px 40px 40px",
    marginBottom: 40,
    top: "100px",
    position: "relative",
    maxWidth: "800px",
    width: "100%",
    boxShadow: Depths.depth16,
    selectors: {
      "@media (max-width: 960px)": {
          top: "53px !important",
          padding: "0px 0px 0px",

      }
    }
    
  },  
  ':global(.title)': {
    background: theme.palette.neutralLighter,
    display:"block",
    marginTop:"0px",
    marginLeft:"-40px",
    marginRight:"-40px",
    padding:"20px",
    paddingLeft:"40px",
  }
  }
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
