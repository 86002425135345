import React from 'react';
import * as InnoStyles from './styles';
import { initializeIcons } from '@uifabric/icons';
import { Stack, Text } from 'office-ui-fabric-react';
import { SubscribeWizard } from './SubscribeWizard';

initializeIcons();

const App = () => {

  let Step:number = 1;
  let _state = '';
  let _tenant = '';

  // Simple but effective way to parse url params.
  const getQueryStringParams = (query:any) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params:any, param:any) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
  };

  const parsedUrl = getQueryStringParams(window.location.search)

  if(parsedUrl.Step !== undefined) { // Verify that we have a valid redirect uri with Step
    if(parsedUrl.admin_consent === 'True') // Verify that the consent has been properly given.
      Step = Number.parseInt(parsedUrl.Step); // Set Step in Wizard
      console.log('got this number:', Step);
      _state = parsedUrl.state;
      _tenant = parsedUrl.tenant
  }

  return (
    <Stack>
      <Text
        as="p"
        styles={InnoStyles.h1Style}
        className="title"
        variant="xxLarge"
      >
        TeamsMate Subscription
      </Text>
      <React.Fragment>
        <SubscribeWizard Step={Step} state={_state} tenant={_tenant} />
      </React.Fragment>
    </Stack>
  );
}

export default App;
