/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, FunctionComponent, ReactElement, useState, useEffect } from 'react';
import { StackItem, Stack, Text, TextField, PrimaryButton, ProgressIndicator, Spinner, SpinnerSize, Checkbox } from 'office-ui-fabric-react';
import * as InnoStyles from './styles';
import { validate as uuidValidate } from 'uuid';
import * as DataLayer from './DataLayer';

export interface WizardProps {
    Step?: number;
    state?: string;
    tenant?: string;
}

export const SubscribeWizard: FunctionComponent<WizardProps> = (props): ReactElement => {

    const [verificationCode, setVerificationCode] = useState('');
    const [errorMsgCode, setErrorMsgCode] = useState('');
    const [errorMsgEmail, setErrorMsgEmail] = useState('');
    const [errorMsgTenantID, setErrorMsgTenantID] = useState('');
    const [errorMsgAgreement, setErrorMsgAgreement] = useState('');
    const [processErrorMsg, setProcessErrorMsg] = useState('');
    
    const [currentStep, setCurrentStep] = useState(props.Step !== undefined ? props.Step : 1); // useState(5);
    const [percentComplete, setPercentComplete] = React.useState(props.Step === 3 || props.Step === 4 ? 0.3 : 0.01);
    const [consentSuccessSpinner, setConsentSuccessSpinner] = useState(true);
    const [adminEmail, setAdminEmail] = useState(props.state !== undefined ? props.state : '');
    const [tenantID, setTenantID] = useState(props.tenant !== '' ? props.tenant : '');
    const [agreementAccepted, setAgreementAccepted] = useState(false);
    //const [installSuccess, setInstallSuccess] = useState(false);

    

    const TM_APPID = process.env.REACT_APP_TM_APP_ID;
    const TM_BACKAPPID = process.env.REACT_APP_TM_BACK_APP_ID;

    const handleVerificationCode = (e:any, newValue:any) => {
        setVerificationCode(newValue);
    };

    const handleAdmineMail = (e:any, newValue:any) => {
        setAdminEmail(newValue);
    };

    const handleTenantID = (e:any, newValue:any) => {
        setTenantID(newValue);
    };

    const ProgressDescription = (
        <Fragment>
            <Stack tokens={InnoStyles.progressStackTokens} styles={InnoStyles.progressStackStyles}>
                <StackItem grow={1} styles={currentStep === 1 ? InnoStyles.progressSelectedStackItemStyles : InnoStyles.progressStackItemStyles}>1. Code verification</StackItem>
                <StackItem grow={1} styles={currentStep === 2 ? InnoStyles.progressSelectedStackItemStyles : InnoStyles.progressStackItemStyles}>2. Personal information</StackItem>
                <StackItem grow={1} styles={currentStep === 3 ? InnoStyles.progressSelectedStackItemStyles : InnoStyles.progressStackItemStyles}>3. Process input data</StackItem>
                <StackItem grow={1} styles={currentStep === 4 ? InnoStyles.progressSelectedStackItemStyles : InnoStyles.progressStackItemStyles}>4. Finalize subscription</StackItem>
            </Stack>
        </Fragment>
    );

    const handleAgreement = (ev:any, checked:boolean | undefined) => {
        if(checked !== undefined) {
            setAgreementAccepted(checked);
            setErrorMsgAgreement('');
        }
    }

    /**
     * Installs TeamsMate via Graph to customer environment (STEP 4)
     */
    //const installTeamsMate = () => {
        //setPercentComplete(0.8);
        /*DataLayer.installTeamsMate(tenantID as string).then((response:any) => {
            if(response.name === 'TeamMate' && response.id !== '') {
                setPercentComplete(1.0);
            }
        }).catch(error => {
            console.error('Error installing TeamsMate:', error);
        });*/
    //};

    /**
     * Verify the entered Verification code (STEP 1)
     */
    const verifyCode = () => {
        if(uuidValidate(verificationCode)) {
            DataLayer.checkVerificationCode(verificationCode).then((response:any) => {
                if(response.Table1 !== undefined) {
                    if(response.Table1[0].VCode === verificationCode && response.Table1[0].Used === false) {
                        setCurrentStep(2);
                        setPercentComplete(0.25);
                    } else {
                        setErrorMsgCode('Verification code is invalid');
                        setTimeout(() => { setErrorMsgCode('') }, 5000);            
                    }
                } else {
                    setErrorMsgCode('Verification code is invalid');
                    setTimeout(() => { setErrorMsgCode('') }, 5000);            
                }
            }).catch(error => {
                console.error('Something went wrong:', error);
                setErrorMsgCode('System error, please inform solution provider: ' + error);
                setTimeout(() => { setErrorMsgCode('') }, 10000);
            })
        } else { // 
            setErrorMsgCode('Verification code is invalid');
            setTimeout(() => { setErrorMsgCode('') }, 5000);
        }
    };

    /**
     * Verify the entered personal information (STEP 2)
     */    
    const verifyPersonalInformation = () => {

        let test_a_OK, test_b_OK, test_c_OK = false;

        // regex for email verification
        const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if(!emailRegEx.test(String(adminEmail).toLowerCase())) {
            setErrorMsgEmail('Invalid email address');
            setTimeout(() => { setErrorMsgEmail('') }, 5000);
        } else { test_a_OK = true; }

        if(tenantID !== undefined) {
            if(!uuidValidate(tenantID)) {
                setErrorMsgTenantID('Invalid Tenant ID');
                setTimeout(() => { setErrorMsgTenantID('') }, 5000);
            } else { test_b_OK = true; }
        } else {
            console.error('unknown or invalid tenant id');
        }

        if(!agreementAccepted) {
            setErrorMsgAgreement('You need to agree');
            setTimeout(() => { setErrorMsgAgreement('') }, 5000);
        } else { test_c_OK = true; }

        // If all above checks have been passed, redirect user to constent to permissions for our service.
        if(test_a_OK && test_b_OK && test_c_OK) {
            setPercentComplete(0.5);
            let url =  `https://login.microsoftonline.com/${tenantID}/adminconsent?client_id={${TM_APPID}}&redirect_uri=` + 
                        window.location.origin + '/?Step=3&state=' + adminEmail;
            window.location.href = url;
        }
    };

    // Assume we got a consent, continue to process 
    useEffect(() => {
        const RunMeOnce = async () => {
            if(props.Step !== undefined) {
                if(props.Step === 3) {                
                    let url =  `https://login.microsoftonline.com/${tenantID}/adminconsent?client_id={${TM_BACKAPPID}}&redirect_uri=` + 
                        window.location.origin + '/?Step=4&state=' + adminEmail;
                    window.location.href = url;
                }
                if(props.Step === 4) {
                    setPercentComplete(0.6);
                    // Call API to create customer/tenant databases
                    const response = await DataLayer.createCustomerDBs(tenantID as string);
                    if(response === 200) { // verify we got a 200 http code as response
                        setPercentComplete(0.65);
                        // First get the userid from admin, then add admin to AdminUser
                        const userID = await DataLayer.getUserIDFromEMail(adminEmail, tenantID as string) as string;
                        if(uuidValidate(userID)) { // Verify we got a GUID
                            setPercentComplete(0.7);
                            DataLayer.addAdmin(adminEmail, userID, tenantID as string).then(response => {
                                setPercentComplete(0.75);
                                setConsentSuccessSpinner(false);
                                setCurrentStep(5);
                            }).catch(err => {
                                console.error('TM 253');
                                setProcessErrorMsg(`Error(253) while adding "${adminEmail}" as administrator in TeamsMate. Have you previously tried to subscribe to our service ? Please contact our support at support@teamsmate.fi`);
                                setConsentSuccessSpinner(false);

                            });
                        } else {
                            console.error('TM 252');
                            setProcessErrorMsg(`Error(252) while verifying your email address "${adminEmail}" ? Please contact our support at support@teamsmate.fi`);
                            setConsentSuccessSpinner(false);
                        }
                    } else {
                        console.error('TM 251');
                        setProcessErrorMsg(`Error(251) while creating mandatory backend service. Please contact our support at support@teamsmate.fi`);
                        setConsentSuccessSpinner(false);
                    }
                }
            }
        }
        RunMeOnce();
    }, []);

    return (
        <Fragment>
            { currentStep === 1 && (
            <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
                <Text as="legend" styles={InnoStyles.legendStyles} variant="large">Step 1. Verification code</Text>
                <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                    <StackItem grow styles={InnoStyles.stackItemStyles}>
                        <TextField 
                          autoComplete="off"
                          description="Enter the verification code you received."
                          id="verifycode"
                          required
                          onChange={handleVerificationCode}
                          errorMessage={errorMsgCode}
                        />
                    </StackItem>
                </Stack>
                <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                    <PrimaryButton text='Verify' onClick={verifyCode} />
                </Stack>
            </Stack>
            )}
            { currentStep === 2 && (
                <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
                    <Text as="legend" styles={InnoStyles.legendStyles} variant="large">Step 2. Personal information</Text>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <StackItem grow styles={InnoStyles.stackItemStyles}>
                            <TextField 
                            autoComplete="off"
                            description="Enter your email address (NOTE: you must be a Global Administrator)"
                            id="adminEmail"
                            required
                            onChange={handleAdmineMail}
                            errorMessage={errorMsgEmail}
                            /><br />
                            <TextField 
                            autoComplete="off"
                            description="Enter your Tenant ID"
                            id="TenantID"
                            required
                            onChange={handleTenantID}
                            errorMessage={errorMsgTenantID}
                            />
                        </StackItem>
                    </Stack>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <PrimaryButton text='Verify' onClick={verifyPersonalInformation} />
                    </Stack>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <Text as="span" styles={InnoStyles.boldStyle} variant="medium">Please note: Once you click verify, we will redirect you twice to consent to the permissions required for our Frontend and Backend services for TeamsMate. You will return here.</Text>
                    </Stack>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <Text as="strong" styles={InnoStyles.boldStyle} variant="small">Explanation of requested permissions:</Text>
                        <Text as="span" styles={InnoStyles.textStyle} variant="small">
                            <ul style={{marginTop: 0}}>
                                <li>AppCatalog.ReadWrite.All: At STEP 4 we will install TeamsMate to your Tenant. This permission allows this.</li>
                                <li>AppRoleAssignment.ReadWrite.All: TeamsMate uses a custom claim role called "isAdmin". It will be assigned to users in the TeamsMate Settings. But also to you initially</li>
                                <li>User.ReadBasic.All: Grants TeamsMate permission to read your tenants users.</li> 
                            </ul>
                        </Text>
                    </Stack>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <Checkbox label="Understood and agree" onChange={handleAgreement} />
                        
                            
                    </Stack>
                    { agreementAccepted === false && errorMsgAgreement !== '' && (
                        <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                            <Text as="span" styles={{ root: { color: '#C62828' } }} variant="small">{errorMsgAgreement}</Text>
                        </Stack>
                    )}
                </Stack>
            )}
            { currentStep === 4 && (
                <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
                    <Text as="legend" styles={InnoStyles.legendStyles} variant="large">Step 3. Processing Data</Text>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        { consentSuccessSpinner && (
                            <Spinner size={SpinnerSize.large} label="Processing data and consent..." />
                        )}
                        { processErrorMsg !== '' && (
                            <Text as="legend" styles={InnoStyles.legendStyles} variant="medium">{processErrorMsg}</Text>
                        )}
                    </Stack>
                </Stack>
            )}
            { currentStep === 5 && (
                <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
                    <Text as="legend" styles={InnoStyles.legendStyles} variant="large">Step 4. Finalize subscription</Text>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <PrimaryButton text='Download TeamsMate' href='https://stage.teamsmate.io/public/TeamsManifest_Stage.zip' />
                    </Stack>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <Text as="span" styles={InnoStyles.ingressStyle} variant="medium">
                            The final step in this process is to deploy TeamsMate in your AppCatalog for Teams.<br/>
                            Please click the DownLoad TeamsMate button above to download the .zip package.<br/>
                            For a detailed step by step guide, please click this <a href="https://stage.teamsmate.io/manual/TeamsMate_DeployInstruction.pdf">link</a> (PDF).
                        </Text>
                    </Stack>
                    {/* { installSuccess && (
                        <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                            <Text as="span" styles={InnoStyles.ingressStyle} variant="medium">TeamsMate has been successfully installed to your Tenant</Text>
                        </Stack>
                    )} */}
                </Stack>
            )}
            <ProgressIndicator label="Progress" percentComplete={percentComplete} description={ProgressDescription} barHeight={20} progressHidden={false} />
        </Fragment>
    );
};