import {
  FontWeights,
  FontSizes,
  loadTheme,
  getTheme,
  ILinkStyles,
  ITextStyles,
  IIconStyles,
  IStackTokens,
  IStackItemStyles,
  IButtonStyles,
  IToggleStyles,
  IPartialTheme,
  IStackStyles,
  mergeStyleSets,
  getFocusStyle,
} from "office-ui-fabric-react";
import {
  ICardTokens,
  ICardSectionStyles,
  ICardStyles,
} from "@uifabric/react-cards";
import { Depths } from "@uifabric/fluent-theme/lib/fluent/FluentDepths";

export const lightTheme: IPartialTheme = {
  palette: {
    themePrimary: "#0043a8",
    themeLighterAlt: "#f1f5fc",
    themeLighter: "#cbdaf1",
    themeLight: "#a0bce5",
    themeTertiary: "#a552cc",
    themeSecondary: "#ca0090",
    themeDarkAlt: "#003d97",
    themeDark: "#003380",
    themeDarker: "#00265e",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#595959",
    neutralSecondary: "#373737",
    neutralPrimaryAlt: "#2f2f2f",
    neutralPrimary: "#000000",
    neutralDark: "#151515",
    black: "#0b0b0b",
    white: "#ffffff",
  },
};

export const darkTheme: IPartialTheme = {
  palette: {
    themePrimary: "#6093e5",
    themeLighterAlt: "#06070a",
    themeLighter: "#171e29",
    themeLight: "#2b384d",
    themeTertiary: "#cb299d",
    themeSecondary: "#ae1c84",
    themeDarkAlt: "#9ac1ff",
    themeDark: "#aacaff",
    themeDarker: "#c0d8ff",
    neutralLighterAlt: "#0b0b0b",
    neutralLighter: "#212121",
    neutralLight: "#252525",
    neutralQuaternaryAlt: "#2f2f2f",
    neutralQuaternary: "#373737",
    neutralTertiaryAlt: "#595959",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#dedede",
    white: "#0f0f0f",
  },
};

loadTheme(lightTheme);
export let theme = getTheme();

export const isSmallMobileDevice: MediaQueryList = window.matchMedia(
  "(max-width: 767px)"
);

export const boldStyle = {
  root: {
    fontWeight: FontWeights.semibold,
    color: theme.palette.black,
  },
};

export const textStyle = {
  root: {
    fontWeight: FontWeights.regular,
    color: theme.palette.black,
  },
};

export const ingressStyle = {
  root: {
    fontWeight: FontWeights.regular,
    color: theme.palette.black,
    marginTop: 15,
    marginBottom: 15,
    display: "inline-block",
  },
};

export const WelcomeText = {
  root: {
    fontWeight: FontWeights.semibold,
    color: theme.palette.themePrimary,
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
  },
};

export const h1Style: Partial<ITextStyles> = {
  root: {
    fontWeight: FontWeights.semibold,
    color: theme.palette.themePrimary,
    marginBottom: 5,
    "@media (max-width: 960px)": {
      paddingLeft: 20,
      marginLeft: 0,
      marginRight: 0,
    },
  },
};

export const h2Style: Partial<ITextStyles> = {
  root: {
    fontWeight: FontWeights.semibold,
    color: theme.palette.themePrimary,
    fontSize: 18,
    display: "block",
  },
};

export const listStyle = {
  root: {
    fontWeight: FontWeights.regular,
    color: theme.palette.black,
    margin: 0,
    padding: 0,
    listStyleType: "none",
    display: "block",
  },
};

export const dateStyle = {
  root: {
    fontWeight: FontWeights.semibold,
    fontSize: 12,
    color: theme.palette.themePrimary,
    display: "block",
  },
};

export const listItemStyle = {
  root: {
    fontWeight: FontWeights.regular,
    color: theme.palette.black,
    margin: "0 0 10px 0",
    padding: 10,
    fontSize: 14,
    display: "block",
    //backgroundColor: theme.palette.themeLighterAlt,
    //border: "1px solid " +theme.palette.themeLighter,
    background:
      "linear-gradient(45deg, " +
      theme.palette.white +
      " 60%, " +
      theme.palette.themeLighter +
      " 100%);",
    boxShadow: Depths.depth4,
  },
};

export const titleStyle: Partial<ITextStyles> = {
  root: {
    fontWeight: FontWeights.semibold,
    color: theme.palette.themePrimary,
    marginBottom: 15,
  },
};

export const cardTitleStyle: Partial<ITextStyles> = {
  root: {
    fontWeight: FontWeights.semibold,
    color: theme.palette.themePrimary,
    marginBottom: 0,
  },
};

export const iconStyle: Partial<IIconStyles> = {
  root: {
    color: theme.palette.neutralDark,
    verticalAlign: "middle",
    fontSize: 8,
  },
};

export const tooltipIconstyle: Partial<IIconStyles> = {
  root: {
    color: theme.palette.neutralDark,
    verticalAlign: "middle",
    fontSize: 18,
    marginBottom: 25,
    cursor: "Pointer",
  },
};

export const cardIconStyle: Partial<IIconStyles> = {
  root: {
    color: theme.palette.themeTertiary,
    display: "block",
    verticalAlign: "center",
    textAlign: "center",
    fontSize: 60,
    alignSelf: "center",
  },
};

export const cardStyles: ICardStyles = {
  root: {
    minWidth: 0,
    maxWidth: "100%",
    width: "100%",
    background: theme.palette.neutralLighter,
    selectors: {
      ":hover, :focus, :active": {
        boxShadow: Depths.depth16,
        border: 0,
      },
      "@media (max-width: 960px)": {
        maxWidth: "100%",
        width: "100%",
      },
    },
  },
};

export const verticalCardStyles: ICardStyles = {
  root: {
    minWidth: 0,
    maxWidth: "100%",
    width: "100%",
    textAlign: "center",
    background: theme.palette.neutralLighter,
    border: "0px solid",
    borderColor: theme.palette.themeLighterAlt,
    selectors: {
      ":hover, :focus, :active": {
        boxShadow: Depths.depth16,
        border: 0,
      },
      "@media (max-width: 960px)": {
        maxWidth: "100%",
        width: "100%",
      },
    },
  },
};

export const cardSelectedStyles: ICardStyles = {
  root: {
    minWidth: 0,
    maxWidth: "100%",
    width: "100%",
    background: theme.palette.neutralLighter,
    outline: "2px solid " + theme.palette.themeTertiary,
    selectors: {
      ":hover, :focus, :active": {
        boxShadow: Depths.depth16,
        border: 0,
      },
      "@media (max-width: 960px)": {
        maxWidth: "100%",
        width: "100%",
      },
    },
  },
};

export const cardSectionStylesForIcons: ICardSectionStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    minWidth: 100,
    minHeight: 100,
    textAlign: "center",
    backgroundColor: theme.palette.neutralLighter,
    borderRight: "1px solid" + theme.palette.neutralQuaternaryAlt,
  },
};

export const verticalCardSectionStylesForIcons: ICardSectionStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    minWidth: 100,
    minHeight: 120,
    textAlign: "center",
    backgroundColor: theme.palette.white,
    borderRight: "1px solid" + theme.palette.neutralQuaternaryAlt,
    pointerEvents: "none",
  },
};

export const innofactor_logo = {
  width: "155px",
  overflow: "visible",
};

export const innofactor_svg = {
  fill: "#0043a8",
};

export const peoplePickerStyles = {
  root: {
    backgroundColor: theme.palette.white,
  },
};

export const primaryButtonStylesFloatRight = {
  root: {
    backgroundColor: theme.palette.white,
    float: "right",
  },
};

export const footerCardSectionStyles: ICardSectionStyles = {
  root: {
    borderTop: "1px solid" + theme.palette.neutralQuaternaryAlt,
    fontSize: FontSizes.medium,
    paddingTop: 10,
  },
};

export const fieldSetStyles: IStackStyles = {
  root: {
    backgroundColor: theme.palette.neutralLighter,
    padding: "20px",
    borderRadius: "5px",
    border: "0px",
    marginTop: "0px !important",
  },
};

export const legendStyles: ITextStyles = {
  root: {
    display: "block",
    paddingLeft: "0px",
    padding: "10px",
    fontWeight: "500",
    paddingBottom: "20px",
    position: "relative",
    left: "0px",
    top: "39px",
    color: theme.palette.themePrimary,
  },
};

export const primaryButtonStyles: IButtonStyles = {
  root: {
    backgroundColor: theme.palette.themePrimary,
    padding: "17px 15px",
    minWidth: 130,
  },
  rootHovered: {
    backgroundColor: theme.palette.themeTertiary,
    borderColor: theme.palette.themeTertiary,
  },
  rootPressed: {
    backgroundColor: theme.palette.themeTertiary,
    borderColor: theme.palette.themeTertiary,
  },
  textContainer: {
    color: "#fff",
  },
};

export const primaryButtonDisabledStyles: IButtonStyles = {
  root: {
    padding: "17px 15px",
    minWidth: 130,
  },
  rootDisabled: {
    backgroundColor: "rgb(0, 0, 0, 0.1)",
  },
  textContainer: {
    color: "rgb(0, 0, 0, 0.5)",
  },
};

export const secondaryButtonStyles: IButtonStyles = {
  root: {
    backgroundColor: theme.palette.themeSecondary,
    borderColor: theme.palette.themeSecondary,
    padding: "17px 15px",
    minWidth: 130,
  },
  rootHovered: {
    backgroundColor: theme.palette.themeTertiary,
    borderColor: theme.palette.themeTertiary,
  },
  rootPressed: {
    backgroundColor: theme.palette.themeTertiary,
    borderColor: theme.palette.themeTertiary,
    color: theme.palette.white,
  },
  textContainer: {
    color: "#fff",
  },
};

export const linkStyles: ILinkStyles = {
  root: {
    color: theme.palette.themeTertiary,
    selectors: {
      ":hover": {
        color: theme.palette.themePrimary,
        textDecoration: "none",
      },
    },
  },
};

export const toggleStylesChecked: Partial<IToggleStyles> = {
  root: {
    thumb: { color: "red" },
  },
  thumb: { backgroundColor: theme.palette.themePrimary },
  pill: { borderColor: theme.palette.themePrimary },
};

export const stackItemStyles: IStackItemStyles = {
  root: {
    maxWidth: "48%",
    selectors: {
      "@media (max-width: 960px)": {
        maxWidth: "100%",
        width: "100%",
        display: "block",
      },
    },
  },
};

export const welcomeStackStyle: IStackStyles = {
  root: {
    maxWidth: "100%",
    selectors: {
      "@media (max-width: 960px)": {
        maxWidth: "100%",
        width: "100%",
        display: "block",
        padding: "0px 20px",
      },
    },
  },
};

export const cardStackItemStyles: IStackItemStyles = {
  root: {
    maxWidth: "48%",
    selectors: {
      "@media (max-width: 960px)": {
        maxWidth: "100%",
        width: "100%",
        display: "block",
      },
    },
  },
};

export const verticalCardStackItemStyles: IStackItemStyles = {
  root: {
    maxWidth: "33%",
    selectors: {
      "@media (max-width: 960px)": {
        maxWidth: "100%",
        width: "100%",
        display: "block",
      },
    },
  },
};

export const WelcomeStackItemCore: IStackItemStyles = {
  root: {
    //backgroundColor: theme.palette.themeLight,
    height: "100%",
    display: "block",
    padding: "0px",
    margin: "0px",
    width: "100%",
  },
};

export const WelcomeStackHeader = {
  root: {
    backgroundColor: "rgb(243, 242, 241)",
  },
};

export const WelcomeStackItemNews: IStackItemStyles = {
  root: {
    selectors: {
      "@media (max-width: 960px)": {
        marginLeft: "0px !Important",
      },
    },
  },
};

export const TMGlobalNavigation: IStackItemStyles = {
  root: {
    backgroundColor: theme.palette.themePrimary,
    height: 50,
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: "0px 0px",
    position: "fixed",
    top: "0px",
    left: "0px",
    color: theme.palette.white,
    opacity: 0.9,
    zIndex: 1000,
  },
};

export const TMGlobalNavigationLinkStyles: ILinkStyles = {
  root: {
    color: theme.palette.white,
    float: "left",
    display: "flex",
    alignItems: "center",
    height: 50,
    padding: "0 15px",
    opacity: 1,
    selectors: {
      ":hover,:active,:focus": {
        color: theme.palette.white,
        backgroundColor: theme.palette.themeSecondary,
        textDecoration: "none",
      },
    },
  },
};

export const TMGlobalNavigationTitleStyles: ITextStyles = {
  root: {
    color: theme.palette.white,
    float: "left",
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    fontWeight: 500,
    height: 50,
    padding: "0 15px",
    opacity: 1,
    backgroundColor: theme.palette.themeSecondary,
  },
};

export const createNewTemplateButtonStyles: IStackItemStyles = {
  root: {
    marginTop: 20,
  },
};

export const orderSpinnerStyles: IStackItemStyles = {
  root: {
    marginTop: "auto",
    marginBottom: "auto",
  },
};

export const iconButtonStyles: IButtonStyles = {
  root: {
    padding: "10px 5px",
    marginTop: 3,
    marginRight: 2,
    backgGroundColor: "transparent",
  },
  rootHovered: {
    backgGroundColor: "transparent",
  },
  icon: {
    fontSize: "25px",
    height: "auto",
    margin: "0 auto",
    lineHeight: 30,
  },
  iconHovered: {
    color: theme.palette.themeTertiary,
  },
};

export const modalDialogStyle = {
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    padding: "10px",
    borderRadius: "5px",
  },
};

export const modalContentStyle: IStackItemStyles = {
  root: {
    padding: 25,
    textAlign: "center",
  },
};
export const modalFooterStyle: IStackItemStyles = {
  root: {
    position: "absolute",
    bottom: 20,
    textAlign: "center",
    borderTop: "1px solid #ececec",
    width: "95%",
    paddingTop: 10,
    boxSizing: "border-box",
  },
};

export const TemplateListClassNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": { background: theme.palette.neutralLight },
      },
    },
  ],
  itemCellSelected: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      backgroundColor: theme.palette.themeLight,
      boxSizing: "border-box",
      borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": { background: theme.palette.neutralLight },
      },
    },
  ],
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    marginLeft: 10,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    theme.fonts.mediumPlus,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: theme.palette.themePrimary,
      fontWeight: FontWeights.semibold,
    },
  ],
  itemIndex: {
    display: "none",
  },
  icon: {
    alignSelf: "center",
    marginLeft: 10,
    marginRight: 10,
    color: theme.palette.themeTertiary,
    fontSize: "35px",
    flexShrink: 0,
  },
  itemDescription: {
    whiteSpace: "wrap",
  },
  modalDialog: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    padding: "10px",
    borderRadius: "5px",
    maxWidth: 340,
    margin: "0 auto",
  },
});


export const progressStackStyles: IStackStyles = {
  root: {
    flexFlow: "row",
    borderLeft: "1px solid #ccc",
  },
};

export const progressStackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    background: theme.palette.white,
    color: theme.palette.themePrimary,
    display: 'flex',
    justifyContent: 'center',
    borderRight: "1px solid #ccc",
    padding: 5,
    width: '25%'
  },
};

export const progressSelectedStackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    background: theme.palette.themeLighterAlt,
    color: theme.palette.themePrimary,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    borderRight: "1px solid #ccc",
    padding: 5,
    width: '25%',
  },
};

export const progressStackTokens: IStackTokens = {
  childrenGap: 5
};

export const sectionStackTokens: IStackTokens = { childrenGap: "2%" };
export const cardTokens: ICardTokens = { childrenMargin: 12 };
export const formStackTokens: IStackTokens = { childrenGap: 10, padding: 10 };
