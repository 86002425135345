/* eslint-disable array-callback-return */
import axios from 'axios';

/**
 * CreateCustomerDBs 
 * 
 * @param tenantId Tenant ID for whom the databases are supposed to be created
 */
export const createCustomerDBs = async (tenantId:string) => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/ccd',  method: 'POST', data: { 'TenantId': tenantId }, baseURL: process.env.REACT_APP_TM_APIENDPOINT, headers: { Source : "subscription" } })
        .then((response:any) => {
           resolve(response.status as number);
        })
        .catch((error:any) => {
            console.error('error retrieving template: ' + error);
            reject('Error retrieving template: ' + error);
        });
    });
}

/**
 * CheckVerificationCode
 * 
 * @param verificationCode The verification code to check against if valid
 */
export const checkVerificationCode = async (vCode:string) => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/cvc',  method: 'POST', data: { 'VCode': vCode }, baseURL: process.env.REACT_APP_TM_APIENDPOINT, headers: { Source : "subscription" } })
        .then((response:any) => {
           resolve(response.data);
        })
        .catch((error:any) => {
            console.error('error verifying VCode: ', error);
            reject('Error verifying code: ' + error);
        });
    });
}

/**
 * Get user id (guid) from Email address
 * 
 * @param tenantId Tenant ID from which we have to fetch the user id
 * @param userEmail The email address from which we want the user id
 */
export const getUserIDFromEMail = async (userEmail:string, tenantId:string) => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/guidfe',  method: 'POST', data: { 'TenantId': tenantId, 'UserEmail': userEmail }, baseURL: process.env.REACT_APP_TM_APIENDPOINT, headers: { Source : "subscription" } })
        .then((response:any) => {
           resolve(response.data);
        })
        .catch((error:any) => {
            console.error('error retrieving template: ' + error);
            reject('Error retrieving template: ' + error);
        });
    });
}

/**
 * addAdmin Adds an admin to the Graph and TeamsMate customer database.
 * 
 * @param payload JSON object to be passed to the TeamsMate API as payload. -> { UserEmail: <email>, UserID: <AAD-ID> }
 * @param token the authentication token to be passed to the TeamsMate API.
 */
export const addAdmin = async (UserEmail:string, UserID: string, TenantId:string) => {
    //const appRoleIDGraph = await setGraphAdmin(payload['id'], Graphtoken);
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/saa',  method: 'POST', data: { UserEmail: UserEmail, UserID: UserID }, baseURL: process.env.REACT_APP_TM_APIENDPOINT, headers: { Source : 'subscription', TenantId: TenantId } })
        .then((response:any) => {
            resolve(response.status as number);
        })
        .catch((error:any) => {
            reject('Error while setting admins: ' + error);
        });
    });
}

/**
 * Install TeamsMate
 * 
 * @param TenantID The tenant id where to install TeamsMate
 */
export const installTeamsMate = async (TenantId:string) => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/itm',  method: 'POST', data: { TenantId: TenantId }, baseURL: process.env.REACT_APP_TM_APIENDPOINT, headers: { Source : 'subscription', TenantId: TenantId } })
        .then((response:any) => {
            resolve(response.data);
        })
        .catch((error:any) => {
            reject('Error while setting admins: ' + error);
        });
    });
}
